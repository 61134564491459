/* Documentation site component styles */
.document-example {
  border: solid 1px #d3d3d3;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #f4f6f9;
}

.document-example h4:first-of-type {
   margin-top: 0;
}

.document-example p:last-of-type {
   margin-bottom: 0;
}

.document-navigation {
  float: left;
  width: 250px;
  list-style-type: none;
  padding: 0;
}

.document-componentpage {
  margin-left: 275px;
  width: 100%
}

.document-props tr td {
  padding:5px;
}

.document-props thead tr th {
  padding:5px;
}